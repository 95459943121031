import React, { Fragment, useState, useContext, useEffect } from 'react';
import {
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import {
  makeStyles,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  FormGroup,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AppContext } from './data/context';

const useStyles = makeStyles(theme => ({
  section: {
    flex: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  question: {
    flex: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  preview: {
    flex: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    minWidth: 160,
  },
  publishButton: {
    marginLeft: theme.spacing(2),
  },
  media: {
    maxWidth: '100%',
    maxHeight: 300,
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default ({ edit }) => {
  // Parameters
  const { gameId } = useParams();
  const query = useQuery();
  const passcode = query.get('passcode');

  const classes = useStyles();
  const history = useHistory();
  const { questionTypes, prettyQuestionTypes } = useContext(AppContext);
  const [ sections, setSections ] = useState([]);
  const [ openPublishDialog, setOpenPublishDialog ] = useState(false);
  const [ error, setError ] = useState('');

  // ********
  // Sections
  // ********

  const addSection = () => {
    setSections([...sections, {
      title: '',
      questions: [],
    }]);
  };

  const deleteSection = i => {
    return () => {
      let newSections = [...sections];
      newSections.splice(i, 1);
      setSections(newSections);
    };
  };

  const sectionTitleChanged = i => {
    return e => {
      let newSections = [...sections];
      newSections[i].title = e.target.value;
      setSections(newSections);
    };
  };

  // *********
  // Questions
  // *********

  const addQuestion = i => {
    return () => {
      let newSections = [...sections];
      newSections[i].questions = [
        ...newSections[i].questions,
        {
          type: '',
          question: '',
          media: '',
          fuzzy: true,
          pCorrect: -1,
          answer: '',
          answers: [],
          hasBonus: false,
          bonusAnswers: [],
        },
      ];
      setSections(newSections);
    };
  };

  const deleteQuestion = (i, qi) => {
    return () => {
      let newSections = [...sections];
      newSections[i].questions.splice(qi, 1);
      setSections(newSections);
    };
  };

  const questionTypeChanged = (i, qi) => {
    return e => {
      const t = e.target.value;
      let newSections = [...sections];
      newSections[i].questions[qi].type = t;
      newSections[i].questions[qi].correct = 0;
      newSections[i].questions[qi].pCorrect = -1;
      newSections[i].questions[qi].answer = '';
      newSections[i].questions[qi].answers = [];
      newSections[i].questions[qi].hasBonus = false;
      newSections[i].questions[qi].bonusAnswers = [];
      if (t === 'exact') {
        newSections[i].questions[qi].answers = [
          { value: '', correct: true, },
        ];
      }
      newSections[i].questions[qi].bonusAnswers = [
        { value: '', correct: true, },
      ];
      if (t === 'choice' || t === 'multiple') {
        newSections[i].questions[qi].answers = [
          { value: '', correct: false, },
          { value: '', correct: false, },
          { value: '', correct: false, },
          { value: '', correct: false, },
        ];
      }
      setSections(newSections);
    };
  };

  const questionChanged = (i, qi) => {
    return e => {
      let newSections = [...sections];
      newSections[i].questions[qi].question = e.target.value;
      setSections(newSections);
    };
  };

  const mediaChanged = (i, qi) => {
    return e => {
      let newSections = [...sections];
      newSections[i].questions[qi].media = e.target.value;
      setSections(newSections);
    };
  };

  // *****************
  // Fill in the blank
  // *****************

  const fuzzyChanged = (i, qi) => {
    return e => {
      let newSections = [...sections];
      newSections[i].questions[qi].fuzzy = e.target.checked;
      setSections(newSections);
    };
  };

  // **************
  // Closest number
  // **************

  const closestAnswerChanged = (i, qi) => {
    return e => {
      let newSections = [...sections];
      let num = parseFloat(e.target.value);
      if (isNaN(num)) num = '';
      newSections[i].questions[qi].answer = num;
      setSections(newSections);
    };
  };

  // ************************
  // Choose one & Choose many
  // ************************

  const multipleAnswerCorrectChanged = (i, qi, ai) => {
    return e => {
      let newSections = [...sections];
      newSections[i].questions[qi].answers[ai].correct = e.target.checked;
      setSections(newSections);
    };
  };

  const choiceAnswerChanged = (i, qi, ai) => {
    return e => {
      let newSections = [...sections];
      newSections[i].questions[qi].answers[ai].value = e.target.value;
      setSections(newSections);
    };
  };

  const pCorrectChanged = (i, qi) => {
    return e => {
      let newSections = [...sections];
      newSections[i].questions[qi].pCorrect = parseInt(e.target.value);
      setSections(newSections);
    };
  };

  // *******
  // Answers
  // *******

  const addAnswer = (i, qi) => {
    return () => {
      let newSections = [...sections];
      newSections[i].questions[qi].answers = [
        ...newSections[i].questions[qi].answers,
        { value: '', correct: false, },
      ];
      setSections(newSections);
    };
  };

  const deleteAnswer = (i, qi, ai) => {
    return () => {
      let newSections = [...sections];
      newSections[i].questions[qi].answers.splice(ai, 1);
      setSections(newSections);
    };
  };

  // *************
  // Bonus Answers
  // *************

  const bonusChecked = (i, qi) => {
    return e => {
      let newSections = [...sections];
      newSections[i].questions[qi].hasBonus = e.target.checked;
      setSections(newSections);
    };
  };

  const addBonusAnswer = (i, qi) => {
    return () => {
      let newSections = [...sections];
      newSections[i].questions[qi].bonusAnswers = [
        ...newSections[i].questions[qi].bonusAnswers,
        {
          value: '',
          correct: false,
        },
      ];
      setSections(newSections);
    };
  };

  const deleteBonusAnswer = (i, qi, bi) => {
    return () => {
      let newSections = [...sections];
      newSections[i].questions[qi].bonusAnswers.splice(bi, 1);
      setSections(newSections);
    };
  };

  const bonusAnswerChanged = (i, qi, bi) => {
    return e => {
      let newSections = [...sections];
      newSections[i].questions[qi].bonusAnswers[bi].value = e.target.value;
      setSections(newSections);
    };
  };

  // **********
  // Publishing
  // **********

  const publish = () => {
    setOpenPublishDialog(true);
  };

  const handleClosePublishDialog = () => {
    setOpenPublishDialog(false);
  };

  const confirmPublish = () => {
    if (edit) {
      fetch(`https://cubetrivia.com/api/games/${gameId}/edit?passcode=${passcode}`, {
        method: 'PUT',
        body: JSON.stringify({
          data: JSON.stringify({
            trivia: sections,
          }),
        }),
      }).then(r => {
        if (!r.ok) {
          throw r;
        }
        return r.json()
      }).then(d => {
        history.push(`/games/${d.id}?passcode=${d.passcode}`);
      }).catch(e => {
        setError('You are not permissioned to edit this game.');
      });
    } else {
      fetch('https://cubetrivia.com/api/games/new', {
        method: 'POST',
        body: JSON.stringify({
          data: JSON.stringify({
            trivia: sections,
          }),
        }),
      }).then(r => {
        if (!r.ok) {
          throw r;
        }
        return r.json()
      }).then(d => {
        history.push(`/games/${d.id}?passcode=${d.passcode}`);
      });
    }

    setOpenPublishDialog(false);
  }

  useEffect(() => {
    if (!edit || !gameId || !passcode) {
      return;
    }

    fetch(`https://cubetrivia.com/api/games/${gameId}?passcode=${passcode}`, {
      method: 'GET',
    }).then(r => {
      if (!r.ok) {
        throw r;
      }
      return r.json()
    }).then(d => {
      setSections(JSON.parse(d.data).trivia);
    });
  }, [edit, gameId, passcode]);

  return (
    <Fragment>
      {error &&
        <Typography>
          {error}
        </Typography>
      }
      {!error &&
        <Fragment>
          <Typography variant="h3" gutterBottom>
            {edit ? 'edit game' : 'new game'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h5">
                configuration
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5">
                preview
              </Typography>
            </Grid>
          </Grid>
          <Box display='flex' flexDirection='column' flex={1} textAlign='left' mt={2}>
            {sections.map((s, i) => (
              <Grid container key={`section-${i}-grid`} spacing={2}>
                <Grid item xs={6}>
                  <Paper key={`section-${i}-paper`} className={classes.section}>
                    <Box display='flex' justifyContent='space-between' alignItems='flex-end'>
                      <TextField id={`section-title-${i}`} label="category" value={s.title} onChange={sectionTitleChanged(i)} fullWidth/>
                      <IconButton onClick={deleteSection(i)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <Box mt={2} />
                    <Typography variant="h6" gutterBottom>
                      questions
                    </Typography>
                    {s.questions.map((q, qi) => (
                      <Paper className={classes.question} key={`s${i}q${qi}`} variant="outlined">
                        <Box display='flex' justifyContent='space-between' alignItems='flex-end'>
                          <Typography variant="body1" gutterBottom>
                            question {qi+1}
                          </Typography>
                          <IconButton onClick={deleteQuestion(i, qi)}>
                            <CloseIcon />
                          </IconButton>
                        </Box>
                        <TextField id={`question-s${i}q${qi}`} label="question" value={q.question} onChange={questionChanged(i, qi)} fullWidth/>
                        <TextField id={`question-s${i}q${qi}-media`} label="media link (optional)" value={q.media} onChange={mediaChanged(i, qi)} fullWidth/>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="answer-type-label">answer type</InputLabel>
                          <Select
                            labelId="answer-type-label"
                            id="answer-type"
                            value={q.type}
                            onChange={questionTypeChanged(i, qi)}
                          >
                            {questionTypes.map(t => (
                              <MenuItem key={t} value={t}>{prettyQuestionTypes[t]}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {q.type === 'exact' &&
                          <Fragment>
                            <Box>
                              <Tooltip
                                title="answers are always case insensitive, but this option allows small spelling errors such as 'Katey Perri' for 'Katy Perry'"
                                aria-label="allow misspellings"
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox checked={q.fuzzy} onChange={fuzzyChanged(i, qi)} />
                                  }
                                  label="allow misspellings"
                                />
                              </Tooltip>
                            </Box>
                            <Box>
                              <Typography variant="caption">
                                you may enter multiple acceptable answers, e.g. "Biggie", "Biggie Smalls" and "The Notorious B.I.G."
                              </Typography>
                            </Box>
                          </Fragment>
                        }
                        {q.type === 'exact' && q.answers.map((a, ai) => (
                          <Box
                            key={`answer-s${i}q${qi}a${ai}`}
                            display='flex'
                            justifyContent='space-between'
                            alignItems='flex-end'
                          >
                            <TextField
                              id={`answer-s${i}q${qi}a${ai}`}
                              label={`answer ${ai+1}`}
                              value={a.value}
                              onChange={choiceAnswerChanged(i, qi, ai)}
                              fullWidth
                            />
                            <IconButton onClick={deleteAnswer(i, qi, ai)}>
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        ))}
                        {q.type === 'closest' &&
                          <TextField id={`answer-s${i}q${qi}`} label="answer" value={q.answer} onChange={closestAnswerChanged(i, qi)} fullWidth/>
                        }
                        {['choice', 'multiple'].indexOf(q.type) !== -1 && q.answers.map((a, ai) => (
                          <Box
                            key={`answer-s${i}q${qi}a${ai}`}
                            display='flex'
                            justifyContent='space-between'
                            alignItems='flex-end'
                          >
                            <Checkbox checked={a.correct} onChange={multipleAnswerCorrectChanged(i, qi, ai)} />
                            <TextField
                              key={`answer-s${i}q${qi}a${ai}`}
                              id={`answer-s${i}q${qi}a${ai}`}
                              label={`answer ${ai+1}`}
                              value={a.value}
                              onChange={choiceAnswerChanged(i, qi, ai)}
                              fullWidth
                            />
                            <IconButton onClick={deleteAnswer(i, qi, ai)}>
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        ))}
                        <Box>
                          {['exact', 'choice', 'multiple'].indexOf(q.type) !== -1 &&
                            <Button color="secondary" onClick={addAnswer(i, qi)}>
                              Add Another
                            </Button>
                          }
                        </Box>
                        {q.type !== '' &&
                          <Box>
                            <FormControlLabel
                              control={
                                <Checkbox checked={q.hasBonus} onChange={bonusChecked(i, qi)} />
                              }
                              label="bonus answer?"
                            />
                          </Box>
                        }
                        {q.hasBonus && q.bonusAnswers.map((b, bi) =>
                          <Box
                            key={`answer-s${i}q${qi}b${bi}`}
                            display='flex'
                            justifyContent='space-between'
                            alignItems='flex-end'
                          >
                            <TextField
                              id={`answer-s${i}q${qi}a${bi}`}
                              label={`answer ${bi+1}`}
                              value={b.value}
                              onChange={bonusAnswerChanged(i, qi, bi)}
                              fullWidth
                            />
                            <IconButton onClick={deleteBonusAnswer(i, qi, bi)}>
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        )}
                        {q.hasBonus &&
                          <Button color="secondary" onClick={addBonusAnswer(i, qi)}>
                            Add Another
                          </Button>
                        }
                      </Paper>
                    ))}
                    <Button variant="outlined" color="primary" onClick={addQuestion(i)}>
                      Add Question
                    </Button>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper key={`section-${i}-paper`} className={classes.section}>
                    <Typography variant="h3">
                      {s.title}
                    </Typography>
                    {s.questions.map((q, qi) => (
                      <Box key={`question-s${i}q${qi}`} className={classes.preview}>
                        <Typography variant="body1" gutterBottom>
                          {qi+1}. {q.question}
                        </Typography>
                        {q.media &&
                          <img className={classes.media} src={q.media} alt="trivia" />
                        }
                        {q.type === 'exact' &&
                          <TextField fullWidth/>
                        }
                        {q.type === 'closest' &&
                          <TextField fullWidth/>
                        }
                        {q.type === 'choice' &&
                          <RadioGroup aria-label="answer-group" name="answer-group" value={q.pCorrect} onChange={pCorrectChanged(i, qi)}>
                            {q.answers.map((a, ai) => (
                              <FormControlLabel key={ai} value={ai} control={<Radio />} label={a.value} />
                            ))}
                          </RadioGroup>
                        }
                        {q.type === 'multiple' &&
                          <FormGroup>
                            {q.answers.map((a, ai) => (
                              <FormControlLabel
                                key={ai}
                                control={<Checkbox />}
                                label={a.value}
                              />
                            ))}
                          </FormGroup>
                        }
                        {q.hasBonus &&
                          <TextField label="bonus answer" fullWidth/>
                        }
                      </Box>
                    ))
                  }
                  </Paper>
                </Grid>
              </Grid>
            ))}
          </Box>
          <Box mb={2}>
            <Button variant="contained" color="primary" onClick={addSection}>
              Add Category
            </Button>
            <Button className={classes.publishButton} variant="contained" color="secondary" onClick={publish}>
              Publish
            </Button>
          </Box>
          <Dialog
            open={openPublishDialog}
            onClose={handleClosePublishDialog}
            aria-labelledby="publish-dialog-title"
            aria-describedby="publish-dialog-description"
          >
            <DialogTitle id="publish-dialog-title">{"Confirm Publish"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="publish-dialog-description">
                Ready to go? Click publish to create your quiz, or cancel to go back and make changes.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePublishDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={confirmPublish} color="secondary" autoFocus>
                Publish
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      }
    </Fragment>
  );
};