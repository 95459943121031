import React, { Fragment, useState, useEffect } from 'react';
import {
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  gamelink: {
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
  },
  editButton: {
    marginLeft: theme.spacing(1),
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default () => {
  // Styles
  const classes = useStyles();

  // Parameters
  const { gameId } = useParams();
  const query = useQuery();
  const passcode = query.get('passcode');

  // State
  const history = useHistory();
  const [ openInfoSnack, setOpenInfoSnack ] = useState(false);
  const [ snackMessage, setSnackMessage ] = useState('');
  const [ isModifiable, setIsModifiable ] = useState(false);

  const handleCopyLink = () => {
    const el = document.createElement('textarea');
    el.value = `https://cubetrivia.com/games/${gameId}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setSnackMessage('Game link copied to clipboard!');
    setOpenInfoSnack(true);
  };

  const handleCloseInfoSnack = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenInfoSnack(false);
  };

  useEffect(() => {
    fetch(`https://cubetrivia.com/api/games/${gameId}?passcode=${passcode}`, {
      method: 'GET',
    }).then(r => {
      if (!r.ok) {
        throw r;
      }
      return r.json()
    }).then(d => {
      if (d.passcode !== passcode) {
        setIsModifiable(false);
      } else {
        setIsModifiable(true);
      }
    }).catch(e => {
      setIsModifiable(false);
    });
  }, [gameId, passcode]);

  return (
    <Box>
      {isModifiable &&
        <Fragment>
          <Box>
            <Typography variant="body1">
              <strong>IMPORTANT:</strong> Save this URL to your bookmarks so that you
              can come back and start new games or edit the game itself. The passcode
              for this game is {passcode}. <strong>Do not</strong> share this code
              with others, as it can be used to edit the game without you knowing.
            </Typography>
          </Box>
          <Box mt={2}>
            <Button color="primary" onClick={() => history.push(`/games/${gameId}/play?passcode=${passcode}`)}>
              Host a Game
            </Button>
            <Button color="primary" className={classes.editButton} onClick={() => history.push(`/games/${gameId}/edit?passcode=${passcode}`)}>
              Edit the Game
            </Button>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">
              The following link <strong>can be shared</strong> with players of your
              trivia game. Once you start a game, send this link to the players so they
              can join.
            </Typography>
          </Box>
        </Fragment>
      }
      <Box display='flex' mb={2}>
        <Typography>
          Game link:
        </Typography>
        <Typography className={classes.gamelink} id='gamelink' variant="body1" onClick={handleCopyLink}>
          {`https://cubetrivia.com/games/${gameId}/play`}
        </Typography>
      </Box>
      {!isModifiable &&
        <Button onClick={() => history.push(`/games/${gameId}/play`)}>
          Play Game
        </Button>
      }
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openInfoSnack}
        autoHideDuration={2500}
        onClose={handleCloseInfoSnack}
        message={snackMessage}
        action={
          <Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseInfoSnack}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Fragment>
        }
      />
    </Box>
  );
};