import React, { createContext, useReducer } from 'react';
import { questionReducer, answerReducer } from './reducers';
import { questionTypes, prettyQuestionTypes } from './store';

export const AppContext = createContext();

const AppProvider = (props) => {
  const [ questions, dispatchQuestions ] = useReducer(questionReducer, []);

  const [ answers, dispatchAnswers] = useReducer(answerReducer, {});

  return (
    <AppContext.Provider
      value={{
        questionTypes,
        prettyQuestionTypes,
        questions,
        dispatchQuestions,
        answers,
        dispatchAnswers,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}

export default AppProvider;
