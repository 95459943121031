import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  useParams,
  useLocation,
} from "react-router-dom";
import {
  makeStyles,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Backdrop,
  CircularProgress,
  Snackbar,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Peer from 'peerjs';
import levenshtein from 'js-levenshtein';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AppContext } from './data/context';
import Question from './questions/Question';

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />
));

const useStyles = makeStyles(theme => ({
  preview: {
    flex: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
  },
  media: {
    maxWidth: '100%',
    maxHeight: 300,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  gamelink: {
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
  },
  deleteTeam: {
    cursor: 'pointer',
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default () => {
  // Styles
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();

  // Parameters
  const { gameId } = useParams();
  const query = useQuery();
  const passcode = query.get('passcode');

  // State
  const { questions, dispatchQuestions, answers, dispatchAnswers } = useContext(AppContext);
  const [ game, setGame ] = useState({});
  const [ gameState, setGameState ] = useState('connecting');
  const [ peer, setPeer ] = useState(null);
  const [ conns, setConns ] = useState([]);
  const [ conn, setConn ] = useState(null);
  const [ isHost, setIsHost ] = useState(false);
  const [ team, setTeam ] = useState('');
  const [ openTeamDialog, setOpenTeamDialog ] = useState(false);
  const [ tempTeam, setTempTeam ] = useState('');
  const [ teams, setTeams ] = useState([]);
  // const [ teamConns, setTeamConns ] = useState({});
  const [ hoverTeam, setHoverTeam ] = useState({});
  const [ newTeams, setNewTeams ] = useState([]);
  const [ openBackdrop, setOpenBackdrop ] = useState(false);
  const [ openSnack, setOpenSnack ] = useState(false);
  const [ sectionID, setSectionID ] = useState(0);
  const [ questionID, setQuestionID ] = useState(0);
  const [ recap, setRecap ] = useState({});
  const [ results, setResults ] = useState([]);
  const [ openInfoSnack, setOpenInfoSnack ] = useState(false);
  const [ snackMessage, setSnackMessage ] = useState('');

  const tempTeamChanged = e => {
    setTempTeam(e.target.value);
  };

  const joinGame = () => {
    if (teams.indexOf(tempTeam) !== -1) {
      setOpenSnack(true);
      return;
    }
    setTeam(tempTeam);
    setOpenTeamDialog(false);
    conn.send({ type: 'join', team: tempTeam });
  };

  const startGame = () => {
    setGameState('started');
    sendNextQuestion(0, 0);
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleCloseSnack = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  useEffect(() => {
    conns.forEach(cxn => cxn.send({ type: 'teams', teams }));
  }, [conns, teams]);

  useEffect(() => {
    if (newTeams.length === 0 || questions.length === 0) {
      return;
    }
    newTeams.forEach(t => t.conn.send({ type: 'questions', questions }));
    setNewTeams([]);
  }, [newTeams, questions]);

  useEffect(() => {
    const onbeforeunloadFn = () => {
      fetch(`https://cubetrivia.com/api/games/${gameId}/end?passcode=${passcode}`, {
        method: 'POST',
      }).then(r => {
        if (!r.ok) {
          throw r;
        }
      });
    };

    window.addEventListener('beforeunload', onbeforeunloadFn);
    return () => {
      window.removeEventListener('beforeunload', onbeforeunloadFn);
    };
  }, [gameId, passcode]);

  useEffect(() => {
    if (peer) {
      // We don't need to reconnect to anything
      return;
    }

    if (passcode && passcode.length > 0) {
      var host = new Peer(null, { key: 'peerjs', host: 'cubetrivia.com', port: 443, secure: true, path: '/webrtc' });
      setPeer(host);

      host.on('open', function(id) {
        // The game room is being opened by the host
        fetch(`https://cubetrivia.com/api/games/${gameId}/host?passcode=${passcode}&peer=${id}`, {
          method: 'POST',
        }).then(r => {
          if (!r.ok) {
            throw r;
          }
          return r.json()
        }).then(d => {
          setGame(JSON.parse(d.data));
          setIsHost(true);
          setGameState('waiting');
        });
      });

      host.on('connection', function(conn) {
        conn.on('open', function() {
          // Receive messages
          conn.on('data', function(data) {
            switch (data.type) {
            case 'join':
              setTeams(t => [ ...t, data.team ]);
              // setTeamConns(t => ({...t, [data.team]: conn }));
              setNewTeams(t => [ ...t, { team: data.team, conn }]);
              break;
            case 'answer':
              dispatchAnswers({ type: 'answer', ...data });
              break;
            default:
              console.log('unknown data received from peer', data);
            }
          });

          // Add the connection to the list of connections
          setConns(c => [...c, conn]);
        });
      });
    } else {
      // Get the game room by a non-host
      fetch(`https://cubetrivia.com/api/games/${gameId}`, {
        method: 'GET',
      }).then(r => {
        if (!r.ok) {
          throw r;
        }
        return r.json()
      }).then(d => {
        if (!d.host || d.host.length === 0) {
          setGameState('no_host')
        } else {
          // Connect to the remote host
          var player = new Peer(null, { key: 'peerjs', host: 'cubetrivia.com', port: 443, secure: true, path: '/webrtc' });
          setPeer(player);

          player.on('open', function(id) {
            var cxn = player.connect(d.host, { reliable: true });

            cxn.on('open', function() {
              // Receive messages
              cxn.on('data', function(data) {
                switch (data.type) {
                case 'state':
                  setGameState(data.state);
                  if (['game_summary', 'round_summary'].indexOf(data.state) !== -1) {
                    dispatchQuestions({ type: 'clear' });
                    dispatchAnswers({ type: 'clear' });
                  }
                  break;
                case 'teams':
                  setTeams(data.teams);
                  break;
                case 'question':
                  dispatchQuestions({ type: 'add', question: data.question });
                  dispatchAnswers({ type: 'add', question: data.number });
                  break;
                case 'questions':
                  if (data.questions.length === questions.length) {
                    return;
                  }

                  dispatchQuestions({ type: 'replace', questions: data.questions });
                  dispatchAnswers({ type: 'replace', questions: data.questions.length });
                  break;
                case 'results':
                  setResults(r => [...r, data.results]);
                  setRecap({});
                  break;
                case 'recap':
                  setRecap({ number: data.number, question: data.question });
                  break;
                default:
                  console.log('unknown message type received', data);
                }
              });

              setConn(cxn);
            });
          });
        }
      }).catch(e => {
        // The game was not found
        setGameState('not_found');
      });
    }
  }, [ gameId, passcode, questions, peer, dispatchQuestions, dispatchAnswers ]);

  useEffect(() => {
    if (conn && team.length === 0) {
      setOpenTeamDialog(true);
    }
  }, [ conn, team ]);

  useEffect(() => {
    conns.forEach(cxn => cxn.send({ type: 'state', state: gameState }));

    setOpenBackdrop(gameState === 'connecting');
  }, [ conns, gameState ]);

  const handleCloseTeamDialog = () => {
    setOpenTeamDialog(false);
  };

  const scoreQuestion = (q, a) => {
    let points = teams.reduce((m, t) => {
      m[t] = 0;
      return m;
    }, {});

    let teamMap = teams.reduce((m, t) => {
      m[t] = true;
      return m;
    }, {});

    let closest = null;
    let closestTeams = [];
    for (let t in a) {
      if (!teamMap[t]) continue;

      let pts = questionPoints(q, a[t]) + a[t].credit;

      if (q.type === 'closest') {
        if (closest === null || pts < closest) {
          closest = pts;
          closestTeams = [t];
        } else if (pts === closest) {
          closestTeams = [...closestTeams, t];
        }
      } else {
        points[t] += pts;
      }

      points[t] += questionBonusPoints(q, a[t]) + a[t].bonusCredit;
    }

    if (q.type === 'closest') {
      for (let t in closestTeams) {
        points[closestTeams[t]] += 1;
      }
    }

    return points;
  };

  const questionPoints = (q, a) => {
    if (!a || !a.answer) return 0;

    switch (q.type) {
    case 'exact':
      if (q.fuzzy) {
        // do the fuzzy match
        for (let qa in q.answers) {
          if (similar(q.answers[qa].value, a.answer.value)) {
            return 1;
          }
        }
      } else {
        // do an exact (case insensitive) match
        for (let qa in q.answers) {
          if (q.answers[qa].value.toLowerCase() === a.answer.value.toLowerCase()) {
            return 1;
          }
        }
      }
      // for now, just do the exact match
      break;
    case 'closest':
      return Math.abs(a.answer.value - q.answer);
    case 'choice':
      if (q.answers[a.answer.value].correct) {
        return 1;
      }
      break;
    case 'multiple':
      let pts = 0;
      for (let as in q.answers) {
        if (q.answers[as].correct === a.answer.value[as].correct) {
          pts++;
        }
      }
      // Award partial points for however many correct responses were given
      return pts/q.answers.length;
    default:
      return 0;
    }

    return 0;
  };

  const questionBonusPoints = (q, a) => {
    if (!a || !a.answer) return 0;

    if (q.hasBonus) {
      for (let qb in q.bonusAnswers) {
        if (similar(q.bonusAnswers[qb].value, a.answer.bonusValue)) {
          return 1;
        }
      }
    }

    return 0;
  };

  const similar = (correct, guess) => {
    return (
      1.0 -
      (
        levenshtein(correct.toLowerCase(), guess.toLowerCase())
        /
        correct.length
      )
      >= 0.75
    );
  }

  const displayResults = (a, s) => {
    let points = null;

    for (let q in a) {
      let scores = scoreQuestion(game.trivia[s].questions[q], a[q]);
      if (!points) {
        points = {...scores};
      } else {
        for (let k in scores) {
          points[k] += scores[k];
        }
      }
    }

    conns.forEach(cxn => cxn.send({ type: 'results', results: points }));
    setResults(r => [...r, points]);
  };

  const sendNextQuestion = (sid, qid, recap) => {
    if (game && game.trivia && game.trivia[sid] && game.trivia[sid].questions[qid]) {
      if (recap) {
        const q = game.trivia[sid].questions[qid];
        setRecap({ number: qid, question: q });
        conns.forEach(cxn => cxn.send({ type: 'recap', number: qid, question: q }));
      } else {
        let q = {...game.trivia[sid].questions[qid]};
        if (q.type === 'multiple') {
          let tq = {...q, answers: [], bonusAnswers: []};
          for (let i in q.answers) {
            tq.answers = [...tq.answers, { value: q.answers[i].value, correct: false }];
          }
          if (tq.hasBonus) {
            for (let i in q.bonusAnswers) {
              tq.bonusAnswers = [...tq.bonusAnswers, { value: q.bonusAnswers[i].value, correct: false }];
            }
          }
          q = tq;
        }

        let newQ = { ...q, round: sid + 1, section: game.trivia[sid].title};

        dispatchQuestions({ type: 'add', question: newQ });
        dispatchAnswers({ type: 'add', question: qid });
        conns.forEach(cxn => cxn.send({ type: 'question', number: qid, question: newQ }));
      }
      return;
    }
    dispatchQuestions({ type: 'clear' });
    dispatchAnswers({ type: 'clear' });
  };

  const startRecap = () => {
    setGameState('recap');
    setQuestionID(0);
    sendNextQuestion(sectionID, 0, true);
  }

  const nextQuestion = () => {
    let sid = sectionID;
    let qid = questionID;

    if (gameState === 'tallying') {
      displayResults(answers, sid);

      if (game.trivia.length === sid + 1) {
        setGameState('game_summary');
      } else {
        setGameState('round_summary');
      }

      dispatchQuestions({ type: 'clear' });
      dispatchAnswers({ type: 'clear' });

      setQuestionID(0);
      setSectionID(i => i+1);

      return;
    }

    if (gameState === 'recap') {
      if (game.trivia[sid].questions.length === qid + 1) {
        displayResults(answers, sid);

        if (game.trivia.length === sid + 1) {
          setGameState('game_summary');
        } else {
          setGameState('round_summary');
        }

        dispatchQuestions({ type: 'clear' });
        dispatchAnswers({ type: 'clear' });

        setQuestionID(0);
        setSectionID(i => i+1);

        setRecap({});

        return;
      }
    }

    if (gameState === 'round_summary') {
      setGameState('started');
      sendNextQuestion(sid, qid);
      return;
    }

    if (game.trivia[sid].questions.length === qid + 1) {
      setGameState('tallying');
      return;
    }

    // Go to the next question
    setQuestionID(i => i+1);
    sendNextQuestion(sid, qid+1, gameState === 'recap');
  };

  const handleCopyLink = () => {
    const el = document.createElement('textarea');
    el.value = `https://cubetrivia.com/games/${gameId}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setSnackMessage('Game link copied to clipboard!');
    setOpenInfoSnack(true);
  };

  const handleCloseInfoSnack = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenInfoSnack(false);
  };

  const answerText = (q, a) => {
    if (!a) return '';

    switch (q.type) {
    case 'exact':
      return a.value;
    case 'closest':
      return a.value;
    case 'choice':
      return q.answers[a.value].value;
    case 'multiple':
      let r = [];
      for (let i in a.value) {
        if (a.value[i].correct) {
          r = [...r, q.answers[i].value];
        }
      }
      return r.join(',');
    default:
      return 'unknown';
    }
  };

  const bonusAnswerText = (a) => {
    return a.bonusValue;
  };

  const answerFrom = q => {
    let r = [];
    switch(q.type) {
    case 'exact':
      for (let i in q.answers) {
        r = [...r, q.answers[i].value];
      }
      return r.join(', ');
    case 'closest':
      return q.answer;
    case 'choice':
      for (let i in q.answers) {
        if (q.answers[i].correct) {
          r = [...r, q.answers[i].value];
        }
      }
      return r.join(',');
    case 'multiple':
      for (let i in q.answers) {
        if (q.answers[i].correct) {
          r = [...r, q.answers[i].value];
        }
      }
      return r.join(',');
    default:
      return "unknown";
    }
  }

  const bonusAnswerFrom = q => {
    let r = [];
    for (let i in q.bonusAnswers) {
      r = [...r, q.bonusAnswers[i].value];
    }
    return r.join(', ');
  }

  const checkAnswer = (q, a) => {
    if (!a || !a.answer) return '';

    return `${answerText(q, a.answer)} (${questionPoints(q, a)}${a.credit > 0 ? ` +${a.credit}` : ''})`;
  }

  const checkBonusAnswer = (q, a) => {
    if (!q.hasBonus || !a || !a.answer) {
      return '';
    }
    return `${bonusAnswerText(a.answer)} (${questionBonusPoints(q, a)}${a.bonusCredit > 0 ? ` +${a.bonusCredit}` : ''})`;
  }

  const markAnswerCorrect = (t, i) => {
    return () => {
      dispatchAnswers({ type: 'credit', question: i, team: t });
    };
  };

  const markAnswerWrong = (t, i) => {
    return () => {
      dispatchAnswers({ type: 'remove_credit', question: i, team: t });
    };
  };

  const markBonusAnswerCorrect = (t, i) => {
    return () => {
      dispatchAnswers({ type: 'bonus_credit', question: i, team: t });
    };
  };

  const markBonusAnswerWrong = (t, i) => {
    return () => {
      dispatchAnswers({ type: 'remove_bonus_credit', question: i, team: t });
    };
  };

  const handleSubmitAnswer = question => {
    return answer => {
      dispatchAnswers({ type: 'answer', team, question, answer });
      conn.send({ type: 'answer', team, question, answer });

      setSnackMessage('Answer submitted');
      setOpenInfoSnack(true);
    };
  };

  const hasAnswer = (answers, i, t) => {
    return answers && answers[i] && answers[i][t] && answers[i][t].answer;
  }

  return (
    <Box>
      {gameState === 'connecting' &&
        <Typography variant="body1">
          Connecting...
        </Typography>
      }
      {gameState === 'not_found' &&
        <Typography variant="body1">
          No games exist with the supplied game ID. Try asking
          the host for the link again.
        </Typography>
      }
      {gameState === 'no_host' &&
        <Typography variant="body1">
          It looks like nobody is hosting this game right now.
          Please refresh the page once the game has been opened
          by the host.
        </Typography>
      }
      {gameState === 'started' && questions.length === 0 &&
        <Typography variant="body1">
          Game started!
        </Typography>
      }
      <Grid container spacing={2}>
        {isHost &&
          <Grid item sm={4} xs={12}>
            <Box>
              <Typography variant="body1">
                You are the host of this game
              </Typography>
            </Box>
          </Grid>
        }
        <Grid item sm={isHost ? 8 : 12} xs={12}>
          {gameState === 'waiting' &&
            <Box display='flex' flex={1} justifyContent={isLarge && isHost ? 'flex-end' : 'flex-start'}>
              <Typography variant="body1">
                Game link:
              </Typography>
              <Typography className={classes.gamelink} id='gamelink' variant="body1" onClick={handleCopyLink}>
                {`https://cubetrivia.com/games/${gameId}`}
              </Typography>
            </Box>
          }
        </Grid>
      </Grid>
      {['round_summary', 'game_summary'].indexOf(gameState) !== -1 && results &&
        <Box mt={4}>
          <Typography variant="h6">
            {gameState === 'round_summary' ? 'Round Over - Scores' : 'Game Over - Final Scores'}
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Team</TableCell>
                  <TableCell align="right">Round Score</TableCell>
                  <TableCell align="right">Game Score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teams.map(t => (
                  <TableRow key={t}>
                    <TableCell>
                      {t} {t === team && ' (you)'}
                    </TableCell>
                    <TableCell align="right">
                      {results[results.length-1][t]}
                    </TableCell>
                    <TableCell align="right">
                      {results.reduce((tot, r) => tot + (r[t] || 0), 0)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      }
      <Grid container direction='row-reverse' spacing={2}>
        <Grid item sm={3} xs={12}>
          {['connecting', 'not_found', 'no_host', 'round_summary', 'game_summary'].indexOf(gameState) === -1 &&
            <Box>
              <Typography variant="h6">
                Teams
              </Typography>
              {teams.map(t => (
                <Box
                  key={t}
                  display='flex'
                  mb={2}
                  onMouseEnter={() => setHoverTeam(h => ({...h, [t]: true}))}
                  onMouseLeave={() => setHoverTeam(h => ({...h, [t]: false}))}
                >
                  {isHost && hoverTeam[t] &&
                    <Tooltip title="Remove team from the game">
                      <CloseIcon className={classes.deleteTeam} onClick={() => setTeams(ts => ts.filter(item => item !== t))} />
                    </Tooltip>
                  }
                  {
                    isHost                                  &&
                    answers                                 &&
                    answers[questionID]                     &&
                    answers[questionID][t]                  &&
                    answers[questionID][t].answer           &&
                    answers[questionID][t].answer.submitted &&
                    <CheckIcon />
                  }
                  <Box key={t} display='flex' flexDirection='column'>
                    <Typography>
                      {t === team ? t + ' (you)' : t}
                    </Typography>
                    {
                      isHost                                  &&
                      answers                                 &&
                      answers[questionID]                     &&
                      answers[questionID][t]                  &&
                      answers[questionID][t].answer           &&
                      answers[questionID][t].answer.submitted &&
                      <Typography variant="caption">
                        ({answerText(questions[questionID], answers[questionID][t].answer)})
                      </Typography>
                    }
                  </Box>
                </Box>
              ))}
            </Box>
          }
        </Grid>
        <Grid item sm={9} xs={12}>
          {isHost && gameState === 'waiting' &&
            <Typography variant="body1">
              Waiting for teams to join...
            </Typography>
          }
          {!isHost && gameState === 'waiting' &&
            <Typography variant="body1">
              Waiting for the host to start the game...
            </Typography>
          }
          {!isHost && gameState === 'tallying' &&
            <Typography variant="body1">
              Please wait while the host tallies the results...
            </Typography>
          }
          {isHost && questions.length === 0 && gameState === 'waiting' &&
            <Box mt={2}>
              <Button color="primary" onClick={startGame}>
                Start Game
              </Button>
            </Box>
          }
          {isHost && gameState === 'tallying' &&
            <Box mt={2}>
              <Button color="primary" onClick={startRecap}>
                Round Recap
              </Button>
            </Box>
          }
          {isHost && (gameState === 'started' || gameState === 'tallying' || gameState === 'recap' || gameState === 'round_summary') &&
            <Box mt={2}>
              <Button color="primary" onClick={nextQuestion}>
                {gameState === 'started' && questionID !== game.trivia[sectionID].questions.length - 1 && 'Next Question'}
                {gameState === 'started' && questionID === game.trivia[sectionID].questions.length - 1 && 'End Round'}
                {gameState === 'tallying' && 'Show Results'}
                {gameState === 'recap' && questionID !== game.trivia[sectionID].questions.length - 1 && 'Next'}
                {gameState === 'recap' && questionID === game.trivia[sectionID].questions.length - 1 && 'Show Results'}
                {gameState === 'round_summary' && 'Next Question'}
              </Button>
            </Box>
          }
          {gameState === 'started' && questions && questions.length > 0 &&
            <Box mt={2}>
              <Typography variant="h5">
                Round {questions[0].round}: {questions[0].section}
              </Typography>
            </Box>
          }
          {isHost && gameState === 'tallying' && game.trivia[sectionID].questions.map((q, i) => (
            <Box key={i} mt={2}>
              <Typography variant="h6">
                Question: {i+1}
              </Typography>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width="20%">Team</TableCell>
                      <TableCell width="40%">Answer</TableCell>
                      <TableCell width="40%">Bonus</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="20%">(Correct)</TableCell>
                      <TableCell width="40%">{answerFrom(q)}</TableCell>
                      <TableCell width="40%">{bonusAnswerFrom(q)}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {teams.map(t => hasAnswer(answers, i, t) &&
                      <TableRow key={t}>
                        <TableCell width="20%">
                          {t}
                        </TableCell>
                        <TableCell width="40%">
                          <Box display='flex' alignItems='center'>
                            {checkAnswer(game.trivia[sectionID].questions[i], answers[i][t])}
                            {
                              game.trivia[sectionID].questions[i].type === 'exact'                     &&
                              questionPoints(game.trivia[sectionID].questions[i], answers[i][t]) === 0 &&
                              answers[i][t].credit === 0                                               &&
                              <Tooltip title="Give credit for this answer">
                                <IconButton onClick={markAnswerCorrect(t, i)}>
                                  <CheckIcon />
                                </IconButton>
                              </Tooltip>
                            }
                            {
                              game.trivia[sectionID].questions[i].type === 'exact'                     &&
                              questionPoints(game.trivia[sectionID].questions[i], answers[i][t]) === 0 &&
                              answers[i][t].credit > 0                                                 &&
                              <Tooltip title="Remove credit for this answer">
                                <IconButton onClick={markAnswerWrong(t, i)}>
                                  <CloseIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </Box>
                        </TableCell>
                        <TableCell width="40%">
                          <Box display='flex' alignItems='center'>
                            {checkBonusAnswer(game.trivia[sectionID].questions[i], answers[i][t], t)}
                            {
                              game.trivia[sectionID].questions[i].hasBonus                                  &&
                              questionBonusPoints(game.trivia[sectionID].questions[i], answers[i][t]) === 0 &&
                              answers[i][t].bonusCredit === 0                                               &&
                              <Tooltip title="Give credit for this answer">
                                <IconButton onClick={markBonusAnswerCorrect(t, i)}>
                                  <CheckIcon />
                                </IconButton>
                              </Tooltip>
                            }
                            {
                              game.trivia[sectionID].questions[i].hasBonus                                  &&
                              questionBonusPoints(game.trivia[sectionID].questions[i], answers[i][t]) === 0 &&
                              answers[i][t].bonusCredit > 0                                                 &&
                              <Tooltip title="Remove credit for this answer">
                                <IconButton onClick={markBonusAnswerWrong(t, i)}>
                                  <CloseIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ))}
          {gameState === 'recap' &&
            <Box>
              {recap.question &&
                <Question number={recap.number} question={recap.question} showAnswer/>
              }
            </Box>
          }
          <Box display='flex' flexDirection='column-reverse'>
            {gameState === 'started' && questions.map((q, i) => (
              <Box key={i} className={classes.preview}>
                <Question number={i} question={q} onSubmit={isHost ? null : handleSubmitAnswer(i)} />
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
      <Snackbar open={openSnack} autoHideDuration={2000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="error">
          Your team name must be unique
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openInfoSnack}
        autoHideDuration={2500}
        onClose={handleCloseInfoSnack}
        message={snackMessage}
        action={
          <Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseInfoSnack}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Fragment>
        }
      />
      <Dialog
        open={openTeamDialog}
        onClose={handleCloseTeamDialog}
        aria-labelledby="team-dialog-title"
        aria-describedby="team-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="team-dialog-title">{"Enter a team name"}</DialogTitle>
        <DialogContent>
          <TextField id="team" value={tempTeam} onChange={tempTeamChanged} autoComplete='off' fullWidth autoFocus/>
        </DialogContent>
        <DialogActions>
          <Button onClick={joinGame} color="secondary">
            Join
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleCloseBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};
