import React from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';

export default ({ question, value, onChange, disabled }) => {
  const answerChanged = e => {
    onChange(parseInt(e.target.value));
  }

  return (
    <RadioGroup aria-label="answer-group" name="answer-group" value={value} onChange={answerChanged}>
      {question.answers.map((a, i) => (
        <FormControlLabel key={i} value={i} control={<Radio disabled={disabled}/>} label={a.value} />
      ))}
    </RadioGroup>
  );
};