import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Typography,
  Button,
  Paper,
} from '@material-ui/core';
import Exact from './Exact';
import Closest from './Closest';
import Choice from './Choice';
import Multiple from './Multiple';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
  },
  media: {
    maxWidth: '100%',
    maxHeight: 300,
  },
}));

export default ({ number, question, onSubmit, showAnswer }) => {
  let a = { value: '', bonusValue: '', submitted: false };
  const classes = useStyles();
  const [ answer, setAnswer ] = useState(a);

  useEffect(() => {
    if (showAnswer) {
      let a = { value: '', bonusValue: '', submitted: true };

      switch (question.type) {
      case 'exact':
      {
        let v = [];
        for (let ans in question.answers) {
          v = [...v, question.answers[ans].value];
        }
        a.value = v.join(', ');
        break;
      }
      case 'closest':
      {
        a.value = question.answer;
        break;
      }
      case 'choice':
      {
        a.value = -1;

        for (let ans in question.answers) {
          if (question.answers[ans].correct) {
            a.value = parseInt(ans);
            break;
          }
        }
        break;
      }
      case 'multiple':
      {
        a.value = [...question.answers];
        break;
      }
      default:
        break;
      }

      setAnswer(a);
    } else {
      if (question.type === 'multiple') {
        let values = [];
        for (let i = 0; i < question.answers.length; i++) {
          values = [...values, {correct: false}];
        }
        setAnswer(a => ({ ...a, value: values }));
      }
    }
  }, [question, showAnswer]);

  const submitAnswer = () => {
    let a = { ...answer, submitted: true };
    setAnswer(a);
    onSubmit && onSubmit(a);
  };

  const answerChanged = v => {
    setAnswer(a => ({ ...a, value: v, submitted: false }));
  }

  const bonusAnswerChanged = v => {
    setAnswer(a => ({ ...a, bonusValue: v, submitted: false }));
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant="body1" gutterBottom>
        {number+1}. {question.question}
      </Typography>
      {question.media &&
        <img className={classes.media} src={question.media} alt="trivia" />
      }
      {question.type === 'exact' &&
        <Exact placeholder="answer" value={answer.value} onChange={answerChanged} disabled={showAnswer}/>
      }
      {question.type === 'closest' &&
        <Closest value={answer.value} onChange={answerChanged} disabled={showAnswer}/>
      }
      {question.type === 'choice' &&
        <Choice question={question} value={answer.value} onChange={answerChanged} disabled={showAnswer}/>
      }
      {question.type === 'multiple' &&
        <Multiple question={question} value={answer.value} onChange={answerChanged} disabled={showAnswer}/>
      }
      {question.hasBonus &&
        <Exact placeholder="bonus answer" value={answer.bonusValue} onChange={bonusAnswerChanged} disabled={showAnswer}/>
      }
      <Button onClick={submitAnswer} disabled={!onSubmit || answer.submitted || showAnswer}>
        Submit Answer
      </Button>
    </Paper>
  );
};