export const questionReducer = (state, action) => {
  switch (action.type) {
    case 'add':
      return [ ...state, action.question ];
    case 'clear':
      return [];
    case 'replace':
      return action.questions;
    default:
      return state;
  }
};

export const answerReducer = (state, action) => {
  switch (action.type) {
    case 'answer':
    {
      let s = {...state};
      s[action.question] = {
        ...s[action.question],
        [action.team]: {
          credit: 0,
          bonusCredit: 0,
          answer: action.answer,
        },
      };
      return s;
    }
    case 'credit':
    {
      let s = {...state};
      s[action.question] = {
        ...s[action.question],
        [action.team]: {
          ...s[action.question][action.team],
          credit: 1,
        },
      };
      return s;
    }
    case 'remove_credit':
    {
      let s = {...state};
      s[action.question] = {
        ...s[action.question],
        [action.team]: {
          ...s[action.question][action.team],
          credit: 0,
        },
      };
      return s;
    }
    case 'bonus_credit':
    {
      let s = {...state};
      s[action.question] = {
        ...s[action.question],
        [action.team]: {
          ...s[action.question][action.team],
          bonusCredit: 1,
        },
      };
      return s;
    }
    case 'remove_bonus_credit':
    {
      let s = {...state};
      s[action.question] = {
        ...s[action.question],
        [action.team]: {
          ...s[action.question][action.team],
          bonusCredit: 0,
        },
      };
      return s;
    }
    case 'add':
      return {...state, [action.question]: {}};
    case 'clear':
      return {};
    default:
      return state;
  }
};
