import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import {
  makeStyles,
  Box,
  Container,
} from '@material-ui/core';
import Home from './Home';
import NewGame from './NewGame';
import PlayGame from './PlayGame';
import Game from './Game';

const useStyles = makeStyles(theme => ({
  '@global': {
    'html,body,#root': {
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 0,
    },
  },
}));

export default () => {
  useStyles();

  return (
    <Container maxWidth="lg">
      <Box display='flex' flexDirection='column' pt={5}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/new">
              <NewGame />
            </Route>
            <Route path="/games/:gameId/edit">
              <NewGame edit={true} />
            </Route>
            <Route path="/games/:gameId/play">
              <PlayGame />
            </Route>
            <Route path="/games/:gameId">
              <Game />
            </Route>
          </Switch>
        </Router>
      </Box>
    </Container>
  );
};
