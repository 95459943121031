import React from 'react';
import {
  TextField,
} from '@material-ui/core';

export default ({ value, onChange, disabled }) => {
  const answerChanged = e => {
    let v = parseFloat(e.target.value);
    if (isNaN(v)) v = '';

    onChange(v);
  }

  return (
    <TextField placeholder="answer" variant="outlined" value={value} onChange={answerChanged} fullWidth disabled={disabled}/>
  );
};