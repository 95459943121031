import React from 'react';
import {
  TextField,
} from '@material-ui/core';

export default ({ placeholder, value, onChange, disabled }) => {
  const answerChanged = e => {
    onChange(e.target.value);
  }

  return (
    <TextField placeholder={placeholder} variant="outlined" value={value} onChange={answerChanged} fullWidth disabled={disabled}/>
  );
};