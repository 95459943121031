export const questionTypes = [
  'exact',
  'closest',
  'choice',
  'multiple',
];

export const prettyQuestionTypes = {
  'exact': 'fill in the blank',
  'closest': 'closest number',
  'choice': 'choose one',
  'multiple': 'choose many',
};
