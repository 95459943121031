import React, { useState } from 'react';
import {
  useHistory,
} from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  gameInput: {
    width: 320,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
}));

export default () => {
  const [ game, setGame ] = useState('');
  const history = useHistory();
  const classes = useStyles();

  const gameChanged = (e) => {
    setGame(e.target.value);
  }

  const joinClicked = () => {
    history.push(`/games/${game}/play`);
  };

  const newClicked = () => {
    history.push('/new');
  };

  return (
    <Box textAlign='center'>
      <Box mt={2}>
        <Typography variant="h2">
          join or create a free trivia game!
        </Typography>
      </Box>
      <Box mt={2}>
        <TextField
          className={classes.gameInput}
          id="game"
          label="game id"
          value={game}
          onChange={gameChanged}
          autoComplete='off'
          fullWidth
        />
      </Box>
      <Box mt={1}>
        <Button onClick={joinClicked}>
          Join
        </Button>
      </Box>
      <Box mt={2}>
        <Button onClick={newClicked}>
          Create Game
        </Button>
      </Box>
    </Box>
  );
};