import React from 'react';
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';

export default ({ question, value, onChange, disabled }) => {
  const answerChanged = i => {
    return e => {
      let v = [...value];
      v[i].correct = e.target.checked;
      onChange(v);
    }
  }

  return (
    <FormGroup>
      {value && Array.isArray(value) && value.map((v, i) => (
        <FormControlLabel
          key={i}
          control={
            <Checkbox checked={v.correct} onChange={answerChanged(i)} disabled={disabled}/>
          }
          label={question.answers[i].value}
        />
      ))}
    </FormGroup>
  );
};